import { useEffect, useState } from 'react'
import styled from 'styled-components'
const Wrap = styled.div`
    position: absolute;
    bottom: 1.5rem;
    left: 2vw;
    z-index: 9;
    .dynamic-color {
        max-width: 20vw;
        width: 7rem;
        min-width: 100px;
        height: 7rem;
    }
`

export default function Gomoku({ page, nextPage }: any) {
    const [scrollPercent, setScrollPercent] = useState(0)

    useEffect(() => {
        const nextPercent = +(nextPage * 0.2).toFixed(3)
        if (nextPercent > scrollPercent) {
            const id = setInterval(() => {
                setScrollPercent((prevState) => {
                    return prevState + 0.002 * ((nextPercent - scrollPercent) / 0.2)
                })
            }, 10)
            setTimeout(() => clearInterval(id), 1000)
        }
        if (nextPercent < scrollPercent) {
            const id = setInterval(() => {
                setScrollPercent((prevState) => {
                    return prevState - 0.002 * ((scrollPercent - nextPercent) / 0.2)
                })
            }, 10)
            setTimeout(() => clearInterval(id), 1000)
        }
    }, [nextPage])
    useEffect(() => {
        let step1 = document.getElementById('gomoku-step-1') as any
        let step21 = document.getElementById('gomoku-step-2-1') as any
        let step22 = document.getElementById('gomoku-step-2-2') as any
        let step3 = document.getElementById('gomoku-step-3') as any
        let step41 = document.getElementById('gomoku-step-4-1') as any
        let step42 = document.getElementById('gomoku-step-4-2') as any
        let step51 = document.getElementById('gomoku-step-5-1') as any
        let step52 = document.getElementById('gomoku-step-5-2') as any
        step1.style.strokeDasharray = step1.getTotalLength()
        step21.style.strokeDasharray = step21.getTotalLength()
        step22.style.strokeDasharray = step22.getTotalLength()
        step3.style.strokeDasharray = step3.getTotalLength()
        step41.style.strokeDasharray = step41.getTotalLength()
        step42.style.strokeDasharray = step42.getTotalLength()
        step51.style.strokeDasharray = step51.getTotalLength()
        step52.style.strokeDasharray = step52.getTotalLength()

        step1.style.strokeDashoffset = step1.getTotalLength()
        step21.style.strokeDashoffset = step21.getTotalLength()
        step22.style.strokeDashoffset = step22.getTotalLength()
        step3.style.strokeDashoffset = step3.getTotalLength()
        step41.style.strokeDashoffset = step41.getTotalLength()
        step42.style.strokeDashoffset = step42.getTotalLength()
        step51.style.strokeDashoffset = step51.getTotalLength()
        step52.style.strokeDashoffset = step52.getTotalLength()
    }, [])
    useEffect(() => {
        let step1 = document.getElementById('gomoku-step-1') as any
        let step21 = document.getElementById('gomoku-step-2-1') as any
        let step22 = document.getElementById('gomoku-step-2-2') as any
        let step3 = document.getElementById('gomoku-step-3') as any
        let step41 = document.getElementById('gomoku-step-4-1') as any
        let step42 = document.getElementById('gomoku-step-4-2') as any
        let step51 = document.getElementById('gomoku-step-5-1') as any
        let step52 = document.getElementById('gomoku-step-5-2') as any
        if (scrollPercent < 0.2) {
            let length = step1.getTotalLength()
            step1.style.strokeDashoffset = length * (1 - scrollPercent / 0.2)
            step21.style.strokeDashoffset = step21.getTotalLength()
            step22.style.strokeDashoffset = step22.getTotalLength()
            step3.style.strokeDashoffset = step3.getTotalLength()
            step41.style.strokeDashoffset = step41.getTotalLength()
            step42.style.strokeDashoffset = step42.getTotalLength()
            step51.style.strokeDashoffset = step51.getTotalLength()
            step52.style.strokeDashoffset = step52.getTotalLength()
            return
        } else {
            step1.style.strokeDashoffset = 0
        }
        if (scrollPercent < 0.3) {
            let length = step21.getTotalLength()
            step21.style.strokeDashoffset = length * (1 - (scrollPercent - 0.2) / 0.1)
            step22.style.strokeDashoffset = step22.getTotalLength()
            step3.style.strokeDashoffset = step3.getTotalLength()
            step41.style.strokeDashoffset = step41.getTotalLength()
            step42.style.strokeDashoffset = step42.getTotalLength()
            step51.style.strokeDashoffset = step51.getTotalLength()
            step52.style.strokeDashoffset = step52.getTotalLength()
            return
        } else {
            step21.style.strokeDashoffset = 0
        }
        if (scrollPercent < 0.4) {
            let length = step22.getTotalLength()
            step22.style.strokeDashoffset = length * (1 - (scrollPercent - 0.3) / 0.1)
            step3.style.strokeDashoffset = step3.getTotalLength()
            step41.style.strokeDashoffset = step41.getTotalLength()
            step42.style.strokeDashoffset = step42.getTotalLength()
            step51.style.strokeDashoffset = step51.getTotalLength()
            step52.style.strokeDashoffset = step52.getTotalLength()
            return
        } else {
            step22.style.strokeDashoffset = 0
        }
        if (scrollPercent < 0.6) {
            let length = step3.getTotalLength()
            step3.style.strokeDashoffset = length * (1 - (scrollPercent - 0.4) / 0.2)
            step41.style.strokeDashoffset = step41.getTotalLength()
            step42.style.strokeDashoffset = step42.getTotalLength()
            step51.style.strokeDashoffset = step51.getTotalLength()
            step52.style.strokeDashoffset = step52.getTotalLength()
            return
        } else {
            step3.style.strokeDashoffset = 0
        }
        if (scrollPercent < 0.7) {
            let length = step41.getTotalLength()
            step41.style.strokeDashoffset = length * (1 - (scrollPercent - 0.6) / 0.1)
            step42.style.strokeDashoffset = step42.getTotalLength()
            step51.style.strokeDashoffset = step51.getTotalLength()
            step52.style.strokeDashoffset = step52.getTotalLength()
            return
        } else {
            step41.style.strokeDashoffset = 0
        }
        if (scrollPercent < 0.8) {
            let length = step42.getTotalLength()
            step42.style.strokeDashoffset = length * (1 - (scrollPercent - 0.7) / 0.1)
            step51.style.strokeDashoffset = step51.getTotalLength()
            step52.style.strokeDashoffset = step52.getTotalLength()
            return
        } else {
            step42.style.strokeDashoffset = 0
        }
        if (scrollPercent < 0.9) {
            let length = step51.getTotalLength()
            step51.style.strokeDashoffset = length * (1 - (scrollPercent - 0.8) / 0.1)
            step52.style.strokeDashoffset = step52.getTotalLength()
            return
        } else {
            step51.style.strokeDashoffset = 0
        }
        if (scrollPercent < 1) {
            let length = step52.getTotalLength()
            step52.style.strokeDashoffset = length * (1 - (scrollPercent - 0.9) / 0.1)
            return
        } else {
            step52.style.strokeDashoffset = 0
        }
    }, [scrollPercent])
    return (
        <Wrap>
            <svg
                className='dynamic-color'
                width='170'
                height='202'
                viewBox='0 0 170 202'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                    d='M126.532,94.3614L77.582,106.151l-48.88,11.76-27.62997,6.66'
                    transform='matrix(1.037759 0 0 1.574388 2.219483-62.473056)'
                    fill='none'
                    stroke='#232026'
                    strokeWidth='2'
                    strokeLinecap='round'
                />
                <path
                    d='M126.532,94.3614L77.582,106.151l-48.88,11.76-27.62997,6.66'
                    transform='matrix(1.206921 0 0 1.574388 7.996008-15.293313)'
                    fill='none'
                    stroke='#232026'
                    strokeWidth='2'
                    strokeLinecap='round'
                />
                <path
                    d='M126.532,94.3614L77.582,106.151l-48.88,11.76-27.62997,6.66'
                    transform='matrix(.087428-1.03407 1.568791 0.132638-75.934512 179.110426)'
                    fill='none'
                    stroke='#232026'
                    strokeWidth='2'
                    strokeLinecap='round'
                />
                <path
                    d='M126.532,94.3614L77.582,106.151l-48.88,11.76-27.62997,6.66'
                    transform='matrix(-.023847-1.024486 1.5771-.03671-124.242828 206.044008)'
                    fill='none'
                    stroke='#232026'
                    strokeWidth='2'
                    strokeLinecap='round'
                />
                <path d='' fill='#232026' />
                <path d='' fill='#232026' />
                <path d='' fill='none' stroke='#232026' strokeWidth='0.5' />
                <path d='' fill='none' stroke='#232026' strokeWidth='0.5' />
                <path
                    d='M5.37427,144.24575c11.89901,3.96633,23.37386,8.99314,35.33451,12.98003'
                    fill='none'
                    stroke='#232026'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
                <path
                    d='M34.21877,138.8374c-10.78695,6.16397-18.59042,17.28288-24.87838,27.76283'
                    fill='none'
                    stroke='#232026'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
                <path
                    d='M77.84608,123.69404c-13.36487-5.34595-27.51393,9.66802-12.98002,18.38837c12.28892,7.37335,24.13026-6.51702,14.06169-16.58559'
                    fill='none'
                    stroke='#232026'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
                <path
                    d='M107.05113,108.91123c11.53849,3.29671,22.88485,9.19209,34.6134,11.5378'
                    fill='none'
                    stroke='#232026'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
                <path
                    d='M130.48729,104.58456c-5.70572,7.60763-6.33769,17.87549-13.34058,24.87838'
                    fill='none'
                    stroke='#232026'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
                <path
                    d='M97.31611,172.00858c-7.14839-5.71871-27.81953-3.67436-23.79671,9.73502c1.58244,5.2748,17.81198,9.59029,21.63338,5.7689c3.6766-3.6766,2.16334-11.19192,2.16334-15.86447'
                    transform='translate(.000001 0.000001)'
                    fill='none'
                    id='gomoku-step-1'
                    stroke='#232026'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
                <path
                    d='M42.87212,79.34562L63.78438,89.4412c3.47265,1.15755,6.83505,1.92038,10.45613,2.52389'
                    fill='none'
                    stroke='#232026'
                    id='gomoku-step-2-1'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
                <path
                    d='M66.30828,72.85561c-6.34823,7.40628-12.97192,19.81033-16.58559,28.8445'
                    fill='none'
                    stroke='#232026'
                    id='gomoku-step-2-2'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
                <path
                    d='M116.06504,62.03892c-9.38655-3.75462-25.44114-1.62676-19.10948,12.61947.99176,2.23146,6.79062,2.7514,8.65335,2.88445c10.45877.74705,7.57168-7.86116,7.57168-15.50392'
                    fill='none'
                    id='gomoku-step-3'
                    stroke='#232026'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
                <path
                    d='M23.76264,184.26749c9.51453,1.90291,16.05352,9.08953,24.87838,12.61947'
                    fill='none'
                    id='gomoku-step-4-1'
                    stroke='#232026'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
                <path
                    d='M43.95379,179.2197c-7.00922,5.45161-13.54082,11.68798-20.55171,16.94614'
                    fill='none'
                    stroke='#232026'
                    id='gomoku-step-4-2'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
                <path
                    d='M149.59677,19.13273c-11.02827-4.41131-32.01456,3.88469-24.15727,18.02781c8.53885,15.36993,36.48114,2.12976,22.71504-16.22503'
                    fill='none'
                    id='gomoku-step-5-1'
                    stroke='#232026'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
                <path
                    d='M60.63139,155.59482c0-9.7516,8.2085-18.34728,12.91054-26.40793c14.36619-24.62775,26.13018-50.59513,44.01321-73.35536c13.19876-16.79842,28.27217-30.88431,39.31847-49.2948'
                    fill='none'
                    id='gomoku-step-5-2'
                    stroke='#232026'
                    strokeWidth='3'
                    strokeLinecap='round'
                />
            </svg>
        </Wrap>
    )
}

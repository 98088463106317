import styled from 'styled-components'
import lineImg from '../assets/line.svg'
const Wrap = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #71c0a1;
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ffdddb;
    padding-top: 5rem;
    padding-bottom: 2.5rem;

    strong {
        font-size: 4.5rem;
        line-height: 1;
        display: inline-block;
        width: 100%;
        position: relative;
        z-index: 1;
    }
    // > div {
    //     max-width: 80vw;
    //     width:100%;
    //     justify-content: center;
    //     display: flex;
    //     > div:nth-child(2) {
    //         width: 50%;
    //         padding-left: 5rem;
    //     }
    // }
    .line-bg {
        background-image: url(${lineImg});
        background-repeat: no-repeat;
        height: 121px;
        width: 115%;
        left: -15%;
        position: relative;
        background-size: contain;
        margin-bottom: -1rem;
        margin-top: -1rem;
        > p {
            position: absolute;
            top: 40px;
            right: 0px;
        }
    }
    .main-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .main-number {
        font-weight: 700;
        font-size: 8rem;
        line-height: 1.2;
        text-align: right;
        margin: 0;
        align-self: flex-end;
    }
    .sub-number {
        font-weight: 700;
        font-size: 6rem;
        line-height: 1.2;
        text-align: left;
        margin: 0;
    }
    .title-text {
        font-weight: 700;
        font-size: 2rem;
        line-height: 1.2;
    }
    .description {
        font-weight: 400;
        font-size: 0.95rem;
        line-height: 1.4;
        text-align: right;
    }
    @media only screen and (max-width: 768px) {
        .main-number {
            font-size: 5rem;
        }
        .sub-number {
            font-size: 3rem;
        }
        .line-bg {
            left: -15%;
            margin-bottom: -4rem;
            > p {
                position: absolute;
                top: 20px;
                right: 0px;
            }
        }
    }
`
export default function Statistics() {
    return (
        <Wrap id='statistics'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-8 col-lg-12 offset-0 offset-md-1 offset-lg-0 mb-5">
                        <strong>/Statistics</strong>
                    </div>
                </div>
                <div className="row">
                    <div className="col-10 col-md-5 col-lg-5 offset-1 offset-md-1 offset-lg-0 mb-3">
                        <div className='main-section'>
                            <p className='main-number'>2,500</p>
                            <div className='line-bg'>
                                <p className='title-text'>members on Telegram</p>
                            </div>
                            <p className='description'>
                                900 daily message on average.
                                <br /> Top 70 Vietnam-based communities. <br />
                                Top 2% communities in the world.
                            </p>
                        </div>
                    </div>
                    
                    <div className="col-10 col-md-5 col-lg-6 offset-1 offset-md-1 offset-lg-1">
                        <div>
                            <p className='sub-number'>14,000+</p>
                            <p className='title-text'>total followers on Twitter</p>
                        </div>
                        <div style={{ width: "100%" }} className='d-flex'>
                            <div>
                                <p className='sub-number text-start'>15</p>
                                <p className='title-text'>published researches</p>
                            </div>
                            <div>
                                <p className='sub-number text-start'>3</p>
                                <p className='title-text'>projects incubated</p>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </Wrap>
    )
}

import styled from 'styled-components'
import NotionImg from '../assets/Notion.svg'
import BinocularsImg from '../assets/binoculars.svg'
import LinkImg from '../assets/open-link.svg'
import QuestionMark from '../assets/question-mark.gif'
const Wrap = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #232026;
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ffdddb;
    padding-top: 5rem;
    padding-bottom: 2.5rem;
    strong {
        font-size: 4.5rem;
        line-height: 1;
        display: inline-block;
        width: 100%;
        position: relative;
        z-index: 1;
        margin-top: 30px;
		margin-bottom: 30px;
    }
    .title {
        margin: 0;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.2;
        color: #ffdddb;
        text-align: right;
        cursor: pointer;
    }
    .main{
        width: 80%;
        position: relative;
        padding-bottom: 3rem;

        > .handbook {
            width: 100%;
            height: 100%;
            padding: 2rem;
            background: #ffdddb;
            border-radius: 2.2rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: auto
            }
        }
        .title {
            position: absolute;
            right: 1px;
            bottom: 0.5rem;
        }
    } 
    .side {
        display: flex;
        // width: calc(64% - 1rem);
        flex-direction: column;
        justify-content: space-between;
        > div {
            position: relative;
            padding-bottom: 3rem;
            .title {
                position: absolute;
                right: 1px;
                bottom: 0.5rem
            }
        }
        > div > div {
            width: 100%;
            background: #ffdddb;
            border-radius: 2.2rem;
            cursor: pointer;
            
        }
        .side-block {
            padding: 1.4rem 1.4rem;
            font-size: 1.2rem;
            line-height: 33px;
            color: #000000;
            > p {
                margin: 0px 16px;
            }
            .logo-icon {
                width: 14%;

            }
            
        }
        .open-link-icon {
            width: 20px;
            height: 20px;
        }
    }

`
export default function Research() {
    return (
        <Wrap id='research'>
            <div className='container'>
                <div className='row'>
                    <div className="col-12 col-md-8 col-lg-12 offset-0 offset-md-1 offset-lg-0 mb-3">
                        <strong>/Research</strong>
                    </div>
                </div>
            
                <div className='row'>
                    <div className="col-8 col-lg-4 offset-3 offset-lg-1 mb-4">
                        <div className='main'>
                            <div className='handbook'>
                                <img src={QuestionMark} alt='icon' />
                            </div>
                            <p className='title'>NFT 101 Handbook</p>
                        </div>
                    </div>
                    <div className="col-10 col-md-8 col-lg-6 offset-1 offset-md-2 offset-lg-0">
                        <div className='side'>
                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    window.open(
                                        'https://aancresearch.notion.site/AntiAntiNFTs-Club-Research-1429a4a9217f4e3095f8eeab8ed68363'
                                    )
                                }}>
                                <div className='side-block d-flex align-items-center'>
                                    <img className='logo-icon' src={NotionImg} alt='book' />
                                    <p>Visit our Notion library</p>
                                    <img className='open-link-icon' src={LinkImg} alt='link' />
                                </div>
                                <p className='title'>AANC Library</p>
                            </div>
                            <div
                                onClick={(e) => {
                                    e.stopPropagation()
                                    window.open(
                                        'https://aancresearch.notion.site/233cacdae9d043a4805c80b12b787496?v=8f7bdfbac5dd4b1e9417ead77ece4cdd'
                                    )
                                }}>
                                <div className='side-block d-flex align-items-center'>
                                    <img className='logo-icon' src={BinocularsImg} alt='book' />
                                    <p>Interested? Let’s have a look!</p>
                                    <img className='open-link-icon' src={LinkImg} alt='link' />
                                </div>
                                <p className='title'>Research Articles</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='main'>
                        <div className='handbook'>
                            <img src={QuestionMark} alt='icon' />
                        </div>
                        <p className='title'>NFT 101 Handbook</p>
                    </div> */}
                    {/* <div className='side'>
                        <div
                            onClick={(e) => {
                                e.stopPropagation()
                                window.open(
                                    'https://aancresearch.notion.site/AntiAntiNFTs-Club-Research-1429a4a9217f4e3095f8eeab8ed68363'
                                )
                            }}>
                            <div className='side-block d-flex align-items-center'>
                                <img className='logo-icon' src={NotionImg} alt='book' />
                                <p>Visit our Notion library</p>
                                <img className='open-link-icon' src={LinkImg} alt='link' />
                            </div>
                            <p className='title'>AANC Library</p>
                        </div>
                        <div
                            onClick={(e) => {
                                e.stopPropagation()
                                window.open(
                                    'https://aancresearch.notion.site/233cacdae9d043a4805c80b12b787496?v=8f7bdfbac5dd4b1e9417ead77ece4cdd'
                                )
                            }}>
                            <div className='side-block d-flex align-items-center'>
                                <img className='logo-icon' src={BinocularsImg} alt='book' />
                                <p>Interested? Let’s have a look!</p>
                                <img className='open-link-icon' src={LinkImg} alt='link' />
                            </div>
                            <p className='title'>Research Articles</p>
                        </div>
                    </div> */}
                </div>
            </div>
            
        </Wrap>
    )
}

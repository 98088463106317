import { ReactNode } from 'react'
import styled from 'styled-components'

const Wrap = styled.div`
    background: #4286f5;
    border-radius: 30px;
    height: fit-content;
    width: fit-content;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    &.size-md {
        font-size: 28px;
        line-height: 34px;
        padding: 8px 34px;
    }
    &.size-lg {
        font-size: 1.5rem;
        line-height: 39px;
        padding: 8px 34px;
    }
`
export default function Button({
    children,
    onClick,
    size = 'md',
}: {
    children: ReactNode
    onClick?: () => void
    size?: string
}) {
    return (
        <Wrap
            className={`button size-${size}`}
            onClick={(e) => {
                e.stopPropagation()
                onClick && onClick()
            }}>
            {children}
        </Wrap>
    )
}

import styled from "styled-components";
import Button from "../components/Button";
import Arrow from "../assets/Arrow2.svg";
import Circle from "../assets/circle.svg";
const Wrap = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: #f5ede1;
	scroll-snap-align: start;
	display: flex;
	align-items: center;
    justify-content: center;
	flex-direction: column;
    padding-top: 5rem;
    padding-bottom: 2.5rem;

	p {
		font-size: 1rem;
		line-height: 1.2rem;
		margin: 0px 0px 0.725rem 0px;
	}
	> div {
		display: flex;
		&:nth-child(1) {
			padding-bottom: 2.5rem;
		}
		&:nth-child(2) {
			padding-bottom: 2rem;
		}
	}
    .content {
        padding-top:2.5rem
    }
	.tag-line {
		font-size: 6rem;
		line-height: 6.125rem;
		max-width: 52rem;
		z-index: 2;
        margin: auto;
	}
	#arrow {
		width: 10rem;
        padding-right: 1rem;
		margin-top: -2.5rem;
        img {
            width: 100%;
            height: auto;
        }
	}
	.button {
		margin-top: 24px;
	}

    .circle {
        position: relative;
        display: inline-block;
        > span {
            display: inline-block
            
        }
    }
	.circle-bg {
		width: 100%;
		position: absolute;
        z-index: -1;
        top: -40%;
        left: 2%
	}
	
	@media only screen and (max-width: 768px) {
        .tag-line {
			font-size: 5rem;
			line-height: 5.5rem;
			max-width: 52rem;
			z-index: 2;
			margin: auto;
			padding: 0px 10px;
		}
		#arrow, .description{
			padding-left: 10px;
		}
		.button{
			margin-right: 10px;
			font-size: 1.6rem;
		}
    }
`;
export default function OurStory() {
	return (
		<Wrap id="ourstory">
			<div className="content">
				<strong className="tag-line">
					<span>Get fcked by </span>
					<span className="circle">
						<img className="circle-bg" src={Circle} alt="circle" />
						<span style={{ color: "#4286F5" }}>NFTs</span>
					</span>
					<span> Onset with </span>
					<span style={{ color: "#FF6D3B" }}>martini</span>
				</strong>
			</div>
			<div>
				<div id="arrow">
					<img src={Arrow} alt="arror" />
				</div>
				<div className="description">
					<p>
						A little bit sweet & pungent. A little bit tipsy & stoked.
						<br /> Like a martini, NFT is also addictive.
					</p>
					<p>
						Here, we’re all gamblers doing real business. <br />
						Making a big bet with a new future of art, culture, and technology.
					</p>
					<p>Welcome to AntiAntiNFTs Club.</p>
					<Button onClick={() => window.open("https://discord.com/invite/Yd3kABvUmS")}>
						There’s no going back from here
					</Button>
				</div>
			</div>
		</Wrap>
	);
}

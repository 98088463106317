import styled from "styled-components";
import LeftHeaderBg from "../assets/left-header.svg";
import RightHeaderBg from "../assets/right-header.svg";
import AnncLogo from "../assets/Logo.svg";
import Button from "./Button";
import { useEffect, useState } from "react";
const Wrap = styled.header`
	position: fixed;
	width: 100vw;
	display: flex;
	justify-content: flex-end;
	z-index: 10;
	background-color: #ffdddb;
	padding: 0.8rem 0 0.7rem;
	.header-bg {
		position: absolute;
		top: -20px;
		transition: 0.3s ease;
		user-select: none;

		&--left {
			left: 0;
			max-width: 25vw;
			width: 23%;
			min-width: 220px;
		}

		&--right {
			top: -0.5rem;
			right: 0;
			max-width: 2.5vw;
			width: 4%;
		}

		&.scroll.down {
			top: -45px;
		}

		&.scroll {
			top: -10px;
		}
	}
	
	a {
		text-decoration: none;
		color: #232026;
	}
	> #aanc-logo {
		position: absolute;
		top: 1.5rem;
		left: 2.5vw;
		z-index: 11;
		max-width: 16vw;
		width: 8%;
		min-width: 80px;
		transition: 0.3s ease;
		&.scroll.down {
			transform: translateY(-10px);
		}

		&.scroll {
			transform: translateY(10px);
		}
	}
	.nav-bar {
		display: flex;
		margin-right: 41px;
		align-items: center;

		z-index: 12;

		@media screen and (max-width: 996px) {
			& {
				display: none;
			}
		}
		> .nav-item {
			margin-left: 29px;
			font-size: 1rem;
			border-bottom: 0.25rem solid #4286f5;
			height: fit-content;
			padding-bottom: 0.5rem;
			cursor: pointer;
		}
		> .nav-item.active,
		.nav-item:hover {
			border-bottom: 0.25rem solid #ff6d3b;
		}
	}
	.button {
		margin-right: 38px;
		z-index: 13;
		font-size: 1.2rem;
	}
	#header-bg {
		position: absolute;
		z-index: 10;
		pointer-events: none;
	}

	@media only screen and (max-width: 1000px) {
        #aanc-logo {
			margin-left: 12px;
			min-width: 65px;
		}
    }
	
	@media only screen and (max-width: 600px) {
		.header-bg {
			//display: none;
			top: -40px;
			height: 137px;
			object-fit: cover;
			object-position: 100% 0;
		}
		.header-bg--right{
			display:none;
		}
		#aanc-logo {
			margin-left: 24px;
			min-width: 60px;
		}
	}
	
`;
export default function Header({ page, setPage, isScrolling, scrollDirector }: any) {
	const setNextPage = (nextPage: number) => {
		setPage(nextPage);
	};
	return (
		<Wrap>
			{/* <img id='header-bg' alt='aanc-logo' src={HeaderBg} /> */}
			<img
				className={`header-bg header-bg--left ${isScrolling ? "scroll" : ""} ${scrollDirector}`}
				alt="bg"
				src={LeftHeaderBg}
			/>
			<img
				className={`header-bg header-bg--right ${isScrolling ? "scroll" : ""} ${scrollDirector}`}
				alt="bg"
				src={RightHeaderBg}
			/>
			<img id="aanc-logo" className={` ${isScrolling ? "scroll" : ""} ${scrollDirector}`} alt="aanc-logo" src={AnncLogo} />

			<div className="nav-bar">
				<div className={`nav-item ${page == 0 ? "active" : ""}`} onClick={() => setNextPage(0)}>
					OUR STORY
				</div>
				<div className={`nav-item ${page == 1 ? "active" : ""}`} onClick={() => setNextPage(1)}>
					OUR TEAM
				</div>
				<div className={`nav-item ${page == 2 ? "active" : ""}`} onClick={() => setNextPage(2)}>
					RESEARCH
				</div>
				<div className={`nav-item ${page == 3 ? "active" : ""}`} onClick={() => setNextPage(3)}>
					STATISTICS
				</div>
				{/* <div className={`nav-item ${page == 4 ? "active" : ""}`} onClick={() => setNextPage(4)}>
					PARTNERS
				</div> */}
				<div className={`nav-item ${page == 4 ? "active" : ""}`} onClick={() => setNextPage(4)}>
					AANC LABS
				</div>
			</div>
			<Button size="lg" onClick={() => window.open("https://discord.com/invite/Yd3kABvUmS")}>
				Get fcked with us
			</Button>
		</Wrap>
	);
}

import Header from './components/Header'
import OurStory from './sections/OurStory'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'bootstrap/dist/css/bootstrap.css'
import './App.css'
import styled from 'styled-components'
import OurTeam from './sections/OurTeam'
import Research from './sections/Research'
import Statistics from './sections/Statistics'
import Partners from './sections/Partners'
import Labs from './sections/Labs'
import Footer from './components/Footer'
import FixedComponents from './components/FixedComponents'
import { useEffect, useState, useMemo } from 'react'
import ReactPageScroller from 'react-page-scroller'
import React from 'react'
const Root = styled.div`
    &.light-text {
        .dynamic-color,
        .dynamic-color * {
            animation: color_change_light 0.3s;
            color: #ffdddb;
            stroke: #ffdddb;
            border-color: #ffdddb;
        }
        .dynamic-fill * {
            animation: fill_color_change_light 0.3s;

            fill: #ffdddb;
        }
    }
    &.dark-text {
        .dynamic-color,
        .dynamic-color * {
            animation: color_change_dark 0.3s;
            color: #232026;
            stroke: #232026;
            border-color: #232026;
        }
        .dynamic-fill * {
            animation: fill_color_change_dark 0.3s;

            fill: #232026;
        }
    }
    @keyframes color_change_light {
        from {
            color: #232026;
            stroke: #232026;
            border-color: #232026;
        }
        to {
            color: #ffdddb;
            stroke: #ffdddb;
            border-color: #ffdddb;
        }
    }
    @keyframes fill_color_change_light {
        from {
            fill: #232026;
        }
        to {
            fill: #ffdddb;
        }
    }
    @keyframes color_change_dark {
        from {
            color: #ffdddb;
            stroke: #ffdddb;
            border-color: #ffdddb;
        }
        to {
            color: #232026;
            stroke: #232026;
            border-color: #232026;
        }
    }
    @keyframes fill_color_change_dark {
        from {
            fill: #ffdddb;
        }
        to {
            fill: #232026;
        }
    }
`
const Wrap = styled.div`
    scroll-snap-type: y mandatory;
    height: 100vh;
    overflow: scroll;
`
function App() {
    const [isScrolledDown, setIsScrolledDown] = useState(false)
    const [isScrolling, setIsScrolling] = useState(false)

    const [page, setPage] = useState(0)
    const [nextPage, setNextPage] = useState(0)

    const [scrollDirector, setScrollDirector] = useState("")

    useEffect(() => {
        setScrollDirector(page > nextPage ? "up" : page === nextPage ? "" : "down")
    },[page,nextPage])

    const pageOnChange = (currentPage: any) => {
        setPage(currentPage)
    }

    return (
        <Root className={isScrolledDown ? 'light-text' : 'dark-text'}>
            <FixedComponents
                page={page}
                setPage={(currentPage: any) => {
                    setNextPage(currentPage)
                    setPage(currentPage)
                }}
                nextPage={nextPage}
                isScrolling={isScrolling}
                
            />
            <Header
                page={page}
                setPage={(currentPage: any) => {
                    setNextPage(currentPage)
                    setPage(currentPage)
                }}
                isScrolling={isScrolling}
                scrollDirector={scrollDirector}
            />
            {useMemo(() => {
                return (
                    <>
                        <ReactPageScroller
                            onBeforePageScroll={(p) => {
                                setIsScrolling(true)
                                setTimeout(() => setIsScrolling(false), 1000)
                                p == 0 ? setIsScrolledDown(false) : setIsScrolledDown(true)
                                setNextPage(p)
                            }}
                            customPageNumber={page}
                            pageOnChange={pageOnChange}>
                            <OurStory />
                            <OurTeam />
                            <Research />
                            <Statistics />
                            {/* <Partners /> */}
                            <Labs />
                        </ReactPageScroller>
                    </>
                )
            }, [page])}
            <Footer />
        </Root>
    )
}

export default App

import styled from 'styled-components'
import Shape from '../assets/shape.svg'
import Compass from '../assets/compass.svg'
import Light from '../assets/light.svg'
import Users from '../assets/users.svg'
import WebsiteIcon from '../assets/Globe.svg'
import TwitterIcon from '../assets/TwitterLogo.svg'
const Wrap = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #4286f5;
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #ffdddb;
    strong {
        font-size: 4.5rem;
        line-height: 1;
        display: inline-block;
        width: 100%;
        //padding: 0 24vw;
        position: relative;
        z-index: 1;
    }
    .block {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .image-container {
        background-image: url(${Shape});
        background-repeat: no-repeat;
        background-size: contain;
        padding: 60px 54px;
        > img {
            width: 5rem;
            height: 5rem;
        }
    }
    .title {
        font-weight: 700;
        font-size: 2rem;
        line-height: 1;
        margin: 1rem 0px;
        text-align: right;
    }
    .description {
        font-size: 1rem;
        line-height: 1.4;
        text-align: right;
        width: 100%;
    }
    @media only screen and (max-width: 768px) {
        .image-container {
            padding: 32px 32px;
            > img {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }
`
export default function Labs() {
    return (
        <Wrap id='labs'>
            <div className="container">
				<div className="row">
					<div className="col-12 col-md-8 col-lg-12 offset-0 offset-md-1 offset-lg-0 mb-5">
                        <strong>/AANC Labs</strong>
					</div>
				</div>
				<div className="row">
					<div className="col-5 col-md-5 col-lg-3 offset-0 offset-lg-0 mb-3">
                        <div className='block'>
                            <div className='image-container'>
                                <img src={Compass} alt='logo' />
                            </div>
                            <p className='title'>All-in-one advising</p>
                            <p className='description'>Struggle to survive in this chaotic market? Let us struggle with you.</p>
                        </div>
					</div>
					<div className="col-5 col-md-5 col-lg-3 offset-1 offset-md-0 offset-lg-1">
                        <div className='block'>
                            <div className='image-container'>
                                <img src={Light} alt='logo' />
                            </div>
                            <p className='title'>Content</p>
                            <p className='description'>
                                Is King.
                                <br /> Make it shine, make it divine.
                            </p>
                        </div>
					</div>
					<div className="col-8 col-md-5 col-lg-3 offet-2 offset-md-0 offset-lg-1">
                        <div className='block'>
                            <div className='image-container'>
                                <img src={Users} alt='logo' />
                            </div>
                            <p className='title'>Community</p>
                            <p className='description'>
                                Keep topping your heart
                                <br /> with popping flavors.
                            </p>
                        </div>
					</div>
				</div>
			</div>
        </Wrap>
    )
}

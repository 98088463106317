import styled from "styled-components";
import Slider from "react-slick";
import TinImg from "../assets/Tin.png";
import FarrierImg from "../assets/Farrier.png";
import MaiImg from "../assets/Mai.png";
import KateImg from "../assets/kate.png";
import Bound from "../assets/bound.svg";
import Bound2 from "../assets/bound2.svg";

import Contributor1 from "../assets/2any1.png";
import Contributor2 from "../assets/nancon68.png";
import Contributor3 from "../assets/immihu.jpg";
import Contributor4 from "../assets/hyunmevn.png";
import Contributor5 from "../assets/Cryza69.jpg";
import Contributor6 from "../assets/naomiii1207.png";
import Contributor7 from "../assets/freemintxifu666.png";
import Contributor8 from "../assets/ltdst123.png";
import Contributor9 from "../assets/MeowMeow100987.jpg";
import Contributor10 from "../assets/quangbee2k.png";
import Contributor11 from "../assets/0xdezos.jpg";
import Contributor12 from "../assets/fuhohascome.png";
import Contributor13 from "../assets/joysayhi.png";
import Contributor14 from "../assets/andy.png";
import Contributor15 from "../assets/imted127.png";
import Contributor16 from "../assets/SilvieHoangANW.jpg";
import Contributor17 from "../assets/TruongTomato.jpg";


const Wrap = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: #ff6d3b;
	scroll-snap-align: start;
	display: flex;
	align-items: center;
    justify-content: center;
	flex-direction: column;
	color: #ffdddb;
    padding-top: 5rem;
    padding-bottom: 2.5rem;
	strong {
		font-size: 4.5rem;
        line-height: 1;
        display: inline-block;
        width: 100%;
        position: relative;
        z-index: 1;
	}
	.core-team {
		display: flex;
		background: url(${Bound});
		background-repeat: no-repeat;
		background-size: 100%;
		max-width: 65vw;
		width: 100%;
		padding: 0 2rem;
		justify-content: center;
		margin-top: 8px;
		position: relative;
		margin: auto;
        margin-bottom: 1rem;
		.tag {
			position: absolute;
			font-weight: 700;
			font-size: 3rem;
			line-height: 1;
			background-color: #ff6d3b;
			padding: 0.5rem 0.7rem;
			top: -1.5rem;
			right: 5rem;
		}
		padding-top: 60px;
	}
	.member {
		padding: 0px 1rem 1rem;
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: center;
		cursor: pointer;
		background-color: #ff6d3b;
		border-radius: 50% 50% 0 0;

		> p {
			margin: 0px;
		}
		.member-name {
			font-weight: 700;
			font-size: 2rem;
			line-height: 2.2rem;
			margin-top: 1.2rem;
			padding-bottom: 0.5rem;
		}
		.member-position {
			font-weight: 700;
			font-size: 0.75rem;
			line-height: 0.85rem;
			margin-bottom: 12px;
		}
		.member-description {
			font-size: 0.8rem;
			line-height: 1.3;
			width: 100%;
			text-align: center;
		}
	}
	.community {
		position: relative;
		background: url(${Bound2});
		background-repeat: no-repeat;
		background-size: contain;
        max-width: 85vw;
		// width: 1428px;
		display: flex;
		justify-content: center;
		padding: 2.8rem 2rem 16px;
		margin-top: 20px;
		height: fit-content;
		.tag {
			position: absolute;
			font-weight: 700;
			font-size: 3rem;
			line-height: 1;
			background-color: #ff6d3b;
			padding: 8px;
			top: -50px;
			left: 8rem;
		}
		.contributor-list {
			width: 100%;
            padding: 0 1rem;
            background-color: #ff6d3b;
            border-radius: 50px 50px 0 0;
		}
		.contributor {
			width: 120px;
			cursor: pointer;
			> img {
				margin: 0px auto 0.6rem;
				width: 4rem;
				height: 4rem;
				border-radius: 8px;
			}
			> .contributor-name {
				margin: 0px;
				font-weight: 400;
				font-size: 0.8rem;
				text-align: center;
			}
		}
		/* .contributor-list {
            display: flex;
            overflow: scroll;
            width: 1330px;
            > .contributor {
                > img {
                    margin: 0px 14px 2px;
                }
                > .contributor-name {
                    margin: 0px;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                }
            }
        } */
	}

	@media only screen and (max-width: 768px) {
		.core-team {
			padding-top: 36px;
			.tag {
				font-size: 1.8rem;
			}
		}
		.member {
			.member-name {
				font-weight: 700;
				font-size: 1.4rem;
				line-height: 2.2rem;
				margin-top: 0rem;
				padding-bottom: 0rem;
			}
			.member-description{
				display: none;
			}
		}
		.member-image{
			width: 70%;
		}

		.community {
			width: 100vw;
			max-width: 100vw;
			padding: 2.8rem 0rem;
			margin-top: 20px;
			margin-left: -11px;
			.contributor {
				width: 120px;
				cursor: pointer;
				> img {
					margin: 0px auto 0.6rem;
					width: 4rem;
					height: 4rem;
				}
				> .contributor-name {
					margin: 0px;
					font-weight: 400;
					font-size: 0.8rem;
					text-align: center;
				}
			}
			.tag {
				position: absolute;
				font-weight: 700;
				font-size: 1.8rem;
				line-height: 1;
				background-color: #ff6d3b;
				padding: 8px;
				top: -30px;
				left: 2rem;
			}
			.contributor-list {
				width: 100%;
				padding: 0 0rem;
				background-color: #ff6d3b;
				border-radius: 50px 50px 0 0;
			}
		}
		
    }
`;
export default function OurTeam() {
	const settings = {
		dots: false,
		infinite: true,
		arrows: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 0,
		speed: 3000,
		pauseOnHover: true,
		cssEase: "linear",
        
		// responsive: [
        //     {
		// 		breakpoint: 1580,
		// 		settings: {
		// 			slidesToShow: 8,
		// 		},
		// 	},
		// 	{
		// 		breakpoint: 1310,
		// 		settings: {
		// 			slidesToShow: 7,
		// 		},
		// 	},
		// ],
	};
	const clickHandler = (e: any, link: string) => {
		e.stopPropagation();
		window.open(link);
	};
	return (
		<Wrap id="ourteam">

			<div className="container">
				<div className="row">
					<div className="col-12 col-md-8 col-lg-12 offset-0 offset-md-1 offset-lg-0 mb-3">
						<strong>/Our team</strong>
					</div>
				</div>
				<div className="row">
					<div className="col-12 offset-0">
						<div className="core-team">
							<div className="tag">co-founders</div>

								<div className="row">
									<div className="col-6 col-md-3">
										<div className="member" onClick={(e) => clickHandler(e, "https://twitter.com/Tin_AANC")}>
											<img src={TinImg} alt="Tin Image" className="member-image" />
											<p className="member-name">Tin</p>
											<p className="member-description">Humanism - make your community become a religion.</p>
										</div>
									</div>
									<div className="col-6 col-md-3">
										<div className="member" onClick={(e) => clickHandler(e, "https://twitter.com/sunlit97")}>
											<img src={FarrierImg} alt="Farrier Image" className="member-image" />
											<p className="member-name">Farrier</p>
											<p className="member-description">Without discipline, there's no life at all.</p>
										</div>
									</div>
									<div className="col-6 col-md-3">
										<div className="member" onClick={(e) => clickHandler(e, "https://twitter.com/pinemapple108")}>
											<img src={MaiImg} alt="Mai Image" className="member-image" />
											<p className="member-name">Mai</p>
											<p className="member-description">Part-time dreamer. Full-time thinker.</p>
										</div>
									</div>
									<div className="col-6 col-md-3">
										<div className="member" onClick={(e) => clickHandler(e, "https://twitter.com/katedaynee")}>
											<img src={KateImg} alt="Kate Image" className="member-image" />
											<p className="member-name">Kate</p>
											<p className="member-description">Good vibes. Take things easy.</p>
										</div>
									</div>
								</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 offset-0">
						<div className="community">
							<div className="tag">community contributors</div>
							<div className="contributor-list">
								<Slider {...settings}>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/harryc289")}>
										<img src={Contributor1} alt="contributor" />
										<p className="contributor-name">@2any1</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/NANCON68")}>
										<img src={Contributor2} alt="contributor" />
										<p className="contributor-name">@nancon68</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/immihu")}>
										<img src={Contributor3} alt="contributor" />
										<p className="contributor-name">@immihu</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/hyunmevn")}>
										<img src={Contributor4} alt="contributor" />
										<p className="contributor-name">@hyunmevn</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/Cryza69")}>
										<img src={Contributor5} alt="contributor" />
										<p className="contributor-name">@Cryza69</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/NFTwithNaomi")}>
										<img src={Contributor6} alt="contributor" />
										<p className="contributor-name">@NFTwithNaomi</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "http://freemintxifu666/")}>
										<img src={Contributor7} alt="contributor" />
										<p className="contributor-name">@FreemintXifu666</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/ltdst123")}>
										<img src={Contributor8} alt="contributor" />
										<p className="contributor-name">@Itdst123</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/MeowMeow100987")}>
										<img src={Contributor9} alt="contributor" />
										<p className="contributor-name">@MeowMeow100987</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/QuangBee2k")}>
										<img src={Contributor10} alt="contributor" />
										<p className="contributor-name">@QuangBee2k </p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/0xDezos")}>
										<img src={Contributor11} alt="contributor" />
										<p className="contributor-name">@0xDezos</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/Fuhohascome")}>
										<img src={Contributor12} alt="contributor" />
										<p className="contributor-name">@fuhohascome</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/joy_sayhi")}>
										<img src={Contributor13} alt="contributor" />
										<p className="contributor-name">@joy_sayhi </p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/NguyenDuong2508")}>
										<img src={Contributor14} alt="contributor" />
										<p className="contributor-name">@Andy</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/imted127")}>
										<img src={Contributor15} alt="contributor" />
										<p className="contributor-name">@imted127</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/SilvieHoangANW")}>
										<img src={Contributor16} alt="contributor" />
										<p className="contributor-name">@SilvieHoangANW</p>
									</div>
									<div className="contributor" onClick={(e) => clickHandler(e, "https://twitter.com/TruongTomato")}>
										<img src={Contributor17} alt="contributor" />
										<p className="contributor-name">@TruongTomato</p>
									</div>
								</Slider>

							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrap>
	);
}
